/* .App {
  text-align: center;
} */

* {
  /* user-select: none; */
}

html,
body {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTableCell-root,
.MuiTableCell-root {
  border: 1px solid black !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cancelButton {
  border: 1px solid #0466c8 !important;
  color: #0466c8 !important;
  margin: 0 0.5rem !important;
  width: 6rem !important;
}

.submitButton {
  background-color: #0466c8 !important;
  color: #fff !important;
  margin: 0 0.5rem !important;
  width: 6rem !important;
  cursor: pointer !important;
}

.submitButton:disabled {
  background-color: rgb(226, 137, 137) !important;
}

.submitContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.borderBottomImp {
  border-bottom: 1px solid #0466c8 !important;
}

input[type="checkbox"] {
  accent-color: #0466c8 !important;
  color: white;
  width: 1.2rem;
  color: #0466c8;
}

.otp-input {
  height: 100%;
  width: 100%;
}

.otp-input input {
  background-color: #fff !important;
  width: 3rem !important;
  border-radius: 10px;
  outline: none !important;
  font-size: 2rem !important;
  height: 100%;
  user-select: none;
}

.otp-input div {
  display: flex;
  justify-content: space-between;
  height: 100%;
  /* background-color: coral; */
}

.acknowledgment {
  background: url("./assets/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.acknowledgment-header {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("./assets/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0466c8 !important;
}
